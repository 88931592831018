















import { defineComponent } from '@nuxtjs/composition-api'
import CCardHeader from '~/components/shared/configurable/card/CCardHeader.vue'
import CCardTitle from '~/components/shared/configurable/card/CCardTitle.vue'
export default defineComponent({
  components: { CCardHeader, CCardTitle },
  props: {
    title: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'div'
    },
    titleClasses: {
      type: String
    },
    noShadow: {
      type: Boolean,
      required: false,
      default: false
    },
    bodyClasses: {
      type: [Array, String],
      default: () => []
    },
    headerClasses: {
      type: [Array, String],
      default: () => []
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
