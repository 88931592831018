










import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    slotWrapperClass: { type: [String, Array, Object], default: () => null }
  }
})
