





















import { faStar } from '@fortawesome/free-solid-svg-icons'
import { defineComponent } from '@nuxtjs/composition-api'

/**
 * Based off of: https://codepen.io/filcp/pen/QvZVOg
 */
export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 5
    },
    gradientIntensity: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: "theme('colors.deep-orange.400')"
    }
  },
  computed: {
    icons() {
      return {
        faStar
      }
    },
    foregroundWidth(): string {
      if (this.value > this.max) {
        this.$logger.captureError(
          new Error(
            `Star value ${this.value} should be lower than or equal to max ${this.max}.`
          )
        )
        return '100%'
      }
      return ((this.value / this.max) * 100).toString() + '%'
    }
  }
})
