import { VNode } from 'vue'
import { ensure } from '~/utils/typescript'
import { Ref } from '@nuxtjs/composition-api'

export function useTabs(selectedTabIndex: Ref<number>) {
  function getActiveTab(tabs: VNode[]): VNode {
    return getTabByIndex(tabs, selectedTabIndex.value)
  }

  function getTabByIndex(tabs: VNode[], index: number): VNode {
    return ensure(tabs.find((_node, i) => index === i))
  }

  function tabIsActive(index: number) {
    return selectedTabIndex.value === index
  }

  return { getActiveTab, tabIsActive, getTabByIndex }
}
