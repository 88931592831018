































import { Geolocation } from '~/models/common/types'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

enum Mode {
  PLACE = 'place',
  VIEW = 'view'
}

export default defineComponent({
  props: {
    loadWhenVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '100%'
    },
    height: {
      type: String,
      required: false,
      default: '250px'
    },
    allowFullScreen: {
      type: Boolean,
      required: false,
      default: true
    },
    marker: {
      type: Object as PropType<Geolocation>,
      required: true
    },
    mode: {
      type: String as PropType<Mode>,
      required: false,
      default: Mode.PLACE
    },
    zoom: {
      type: [Number, String],
      required: false,
      default: 15
    }
  },
  data() {
    return {
      loaded: false,
      shouldLoad: !this.loadWhenVisible,
      API_KEY: process.env.GOOGLE_MAPS_EMBED_API_KEY
    }
  },
  computed: {
    src() {
      let url = `https://www.google.com/maps/embed/v1/${this.mode}?key=${this.API_KEY}&zoom=${this.zoom}`
      switch (this.mode) {
        case Mode.PLACE:
          url += `&q=${this.marker.lat},${this.marker.lon}`
          break
        case Mode.VIEW:
          url += `&center=${this.marker.lat},${this.marker.lon}`
      }
      return url
    }
  },
  watch: {
    marker(value: Geolocation, prevValue: Geolocation) {
      if (value.lat === prevValue.lat && value.lon === prevValue.lon) {
        return
      }
      this.forceReload()
    }
  },
  methods: {
    handleLoad() {
      this.loaded = true
    },
    handleContainerVisible(visible: boolean) {
      const { loadWhenVisible, loaded } = this
      if (!loadWhenVisible || !visible || loaded) {
        return
      }

      this.shouldLoad = true
    },
    forceReload() {
      const { map } = this.$refs
      if (!map) {
        return
      }
      this.loaded = false
      const display = map.style.display
      map.style.display = 'none'
      map.style.display = display
    }
  }
})
