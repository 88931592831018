import Vue from 'vue'
import { useScroll } from '~/compositions/scroll'
import { getCurrentInstance } from '@vue/composition-api'
import { onMounted, Ref } from '@nuxtjs/composition-api'
import { useTabRef } from '~/compositions/tab/ref'

export function useTabScroll(
  selectedTabIndex: Ref<any>,
  vertical: boolean = false
) {
  const { scrollTo } = useScroll()
  const { getTabRefName, getNavRefName } = useTabRef()
  const tabsComponent: any = getCurrentInstance()

  function scrollToTabIfOverflown(index: number) {
    if (!tabsComponent) {
      return
    }
    const tabRef = tabsComponent.refs[getTabRefName(index)]
    const tabEl = tabRef instanceof Vue ? tabRef.$el : tabRef
    const tabsNavRef = tabsComponent.refs[getNavRefName()]
    if (!tabRef || !tabEl || !tabsNavRef) {
      return
    }

    const tabIsOverflownInNav =
      tabEl.offsetLeft < tabsNavRef.scrollLeft ||
      tabEl.offsetLeft +
        (tabEl.clientWidth + parseFloat(getComputedStyle(tabEl).marginLeft)) >=
        tabsNavRef.offsetWidth + tabsNavRef.scrollLeft

    if (!tabIsOverflownInNav) {
      return
    }
    scrollTo(tabEl, {
      duration: 300,
      easing: 'ease-out',
      offset: -10,
      container: tabsNavRef,
      y: false,
      x: true
    })
  }

  onMounted(() => {
    if (!vertical) {
      scrollToTabIfOverflown(selectedTabIndex.value)
    }
  })
  return { scrollToTabIfOverflown }
}
