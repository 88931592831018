import VueScrollTo, { ScrollOptions } from 'vue-scrollto'

export function useScroll() {
  function scrollToTop(options?: ScrollOptions) {
    VueScrollTo.scrollTo(document.body, options)
  }

  function scrollTo(element: any, options?: ScrollOptions): void {
    VueScrollTo.scrollTo(element, options)
  }

  return { scrollToTop, scrollTo }
}
