var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outlet-card"},[_c('CTabs',{staticClass:"tw-hidden sm:tw-inline-flex tw-bg-white",attrs:{"pills":"","vertical":""}},_vm._l((_vm.outlets.keys()),function(id){return _c('CTab',{key:id,attrs:{"title":_vm.outlets.get(id).displayName,"active":_vm.activeOutletId === id},on:{"click":function($event){return _vm.setActiveOutletId(id)}}},[_c('OutletCardContent')],1)}),1),_vm._v(" "),_c('div',{staticClass:"lg:tw-hidden"},[_c('div',{staticClass:"small-outlet-select-container",class:{ 'with-text-title': _vm.outletEntries.length === 1 }},[(_vm.outletEntries.length > 1)?_c('CFormSelect',{attrs:{"options":_vm.outletEntries.map(function (ref) {
            var id = ref[0];
            var o = ref[1];

            return (
              o && {
                text: o.displayName,
                value: id
              }
            )
          }),"model-value":_vm.activeOutletId},on:{"change":function($event){return _vm.setActiveOutletId($event)}}}):(_vm.outletEntries.length === 1)?_c('h4',{staticClass:"outlet-title"},[_vm._v("\n        "+_vm._s(_vm.firstOutlet.displayName)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('OutletCardContent',{staticClass:"small-outlet-card-content"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }