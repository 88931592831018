export function useTabRef() {
  function getTabRefName(index: number): string {
    return `tabRef${index}`
  }
  function getNavRefName() {
    return 'tabNavRef'
  }

  return { getTabRefName, getNavRefName }
}
