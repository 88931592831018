



































































































































import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { mapGetters } from 'vuex'
import CHybridTelephone from '~/components/shared/configurable/telephone/CHybridTelephone.vue'
import CGoogleMapEmbed from '~/components/shared/configurable/map/CGoogleMapEmbed.vue'
import CStarBar from '~/components/shared/configurable/rating/CStarBar.vue'
import DirectionsButton from '~/components/car/maps/DirectionsButton.vue'
import OutletSocialIconGroup from '~/components/car/dealers/site/outlet/OutletSocialIconGroup.vue'
import Spinner from '~/components/car/dealers/site/Spinner.vue'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent } from '@nuxtjs/composition-api'
import { ciExternalLink } from '~/icons/source/regular/external-link'

export default defineComponent({
  components: {
    CBadge,
    CGoogleMapEmbed,
    CHybridTelephone,
    DirectionsButton,
    OutletSocialIconGroup,
    Spinner,
    CStarBar
  },
  computed: {
    ...mapGetters({
      outlet: 'dealers/site/activeOutlet'
    }),
    icons(): Record<string, IconDefinition> {
      return {
        externalLink: ciExternalLink
      }
    }
  }
})
