
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string | TranslateResult>
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: '_self'
    },
    useExternalLinkMediator: {
      type: Boolean,
      default: true
    }
  }
})
